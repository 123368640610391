<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-500px">
            <div>
                <p class="title">클래스 선택</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light overhidden overscroll maxh-600px">

                <table class="tbl-light-view">
                    <tbody>
                        <tr>
                            <th width="120">Company</th>
                            <td>
                                <input type="text" v-model="pop.search.company" class="w-100per" @keypress.enter="pop.findClass()">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn-default float-right mt-10" @click="pop.findClass()">검색</button>
                <div class="clear"></div>

                <table class="tbl-light-list mt-50 mb-20">
                    <colgroup>
                        <col width="*">
                        <col width="90">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Class</th>
                            <th>선택</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(itm, idx) in pop.classlist" :key="idx">
                            <td class="txt-left">{{itm.classname}} ({{itm.sdate}}~{{itm.edate}}) – {{itm.tutor_ename}}</td>
                            <td>
                                <button class="btn-default dp-inblock h-30px" @click="pop.setClass(itm)">선택</button>
                            </td>
                        </tr>
                        <tr v-if="pop.classlist.length==0">
                            <td colspan="2">검색된 클래스가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'SelectClassPopup',
    emits: [ 'selected', 'close' ],
    setup(props, {emit}) {
        const pop = reactive({
            classlist : [],
            search: {
                company: ""
            },
            findClass: () => {
                let param = {
                    companyname:pop.search.company
                };
                axios.get('https://itm.carrotenglish.net/rest/carrotians/get_classlist_by_companyname', {params: param}).then((res) => {
                    if(res.data.err == 0) {
                        pop.classlist = res.data.list;
                    }
                });
            },
            setClass: (itm) => {
                emit("selected", {classid:itm.classid, classname:itm.classname + " (" + itm.sdate + " ~ " + itm.edate + ")", hq_name:itm.hq_ename + "(" + itm.hq_kname + ")", hq_idx: itm.hq_code, tutor_name: itm.tutor_ename, tutor_idx: itm.tutor_code })
                emit('close');
            },
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>