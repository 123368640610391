<template>
    <div>
        <div v-if="props.mode=='Write'">
            <table class="table-row mt-10">
                <tbody>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 기간</th>
                        <td>
                            <CarrotDatePicker v-model="comp.info.sdate"></CarrotDatePicker> ~ <CarrotDatePicker v-model="comp.info.edate"></CarrotDatePicker>
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 사유</th>
                        <td>
                            <textarea class="w-100per h-100px" v-model="comp.info.memo"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 주요업무</th>
                        <td>
                            <textarea class="w-100per h-100px" v-model="comp.info.work"></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="props.mode=='View'">
            <table class="table-row mt-10">
                <tbody>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 기간</th>
                        <td>
                            {{comp.info.sdate}} ~ {{comp.info.edate}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 사유</th>
                        <td>
                            <div class="workinfo">{{comp.info.memo}}</div>
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 주요업무</th>
                        <td>
                            <div class="workinfo">{{comp.info.work}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';
import { useToast } from 'vue-toastification';

export default {
    name: 'ExtraPay',
    components: {
        CarrotDatePicker
    },
    props: {
        modelValue: {
            type: [String, Object],
            default: "",
        },
        mode : {
            type : String,
            default: "Write"
        },
        price : {
            type : Number,
            default : 12500,
        }
    },
    setup(props) {
        const toast = useToast()
        const comp = reactive({
            info : {
                sdate:"",
                edate:"",
                memo: "",
                work: ""
            },
            validation : () => {
                let vali = true;
                if(comp.info.sdate=="" || comp.info.edate=="") {
                    vali = false;
                    toast.error("기간을 선택해주세요.");
                }
                if(comp.info.memo=='') {
                    vali = false;
                        toast.error("사유을 입력해주세요.");
                }
                if(comp.info.work=='') {
                    vali = false;
                        toast.error("주요업무을 입력해주세요.");
                }
                return vali;
            },
            getJSON: () => {
                return comp.info;
            },
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal && props.modelValue!=null && props.modelValue!="") {
                comp.info = nVal;
            }
        });

        onMounted(() => {
            // Mounted
            if(props.modelValue!="") {
                comp.info = props.modelValue;
            }
        });

        return {comp, props};
    }
}
</script>
<style lang="scss" scoped>
.dept-select {
    display: inline-block;
    select {
        width: 200px;
        margin-right: 10px;
    }
}
.workinfo {
    white-space: pre-wrap;
    height: auto;
    min-height: 100px;
}
</style>