<template>
    <div>
        <div v-if="props.mode=='Write'">
            <table class="table-row mt-10" v-for="(itm, idx) in comp.items" :key="idx">
                <tbody>
                    <tr>
                        <th width="150">날짜/퇴근시간</th>
                        <td>
                            <CarrotDatePicker class="mr-10" v-model="itm.cdate"></CarrotDatePicker>
                            <CarrotTime24Select :minRange="10" v-model="itm.etime" v-if="itm.cdate"></CarrotTime24Select>
                        </td>
                    </tr>
                    <tr>
                        <th width="150">근무내용</th>
                        <td>
                            <textarea class="w-100per h-100px" v-model="itm.memo" maxlength="500"></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="dp-table w-100per mt-10">
                <button class="btn-default float-left" v-show="comp.items.length<20" @click="comp.addItem()">+ 근무내용 추가</button>
                <button class="btn-default float-right" v-show="comp.items.length>1" @click="comp.removeItem()">- 근무내용 삭제</button>
            </div>
        </div>
        <div v-if="props.mode=='View'">
            <table class="table-row mt-10" v-for="(itm, idx) in comp.items" :key="idx">
                <tbody>
                    <tr>
                        <th width="150">날짜/퇴근시간</th>
                        <td>
                            {{itm.cdate}} &nbsp; &nbsp;
                            {{itm.etime}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150">근무내용</th>
                        <td>
                            <div class="w-100per workinfo">
                            {{itm.memo}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <table class="table-row mt-45 mb-20">
            <tbody>
                <tr>
                    <th width="150">총 합계</th>
                    <td>
                            {{comp.total.price}}원
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="color-1" v-if="props.mode=='Write'">
            ※ 기안 시 Private에 체크해 주세요.<br>
            ※ 8시 이후 퇴근시(20,000원, 식대포함)<br>
            ※ 10시 이후 퇴근시(30,000원, 식대포함)<br>
            ※ 사전 팀장 재가 필수 (ER 미팅 시 보고)<br>
            ※ 퇴근시간 확인 후 지급됩니다.
        </div>
    </div>
</template>
<script>
import { onMounted, reactive, watch, computed } from 'vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';
import CarrotTime24Select from '@/components/common/CarrotTime24Select.vue';

export default {
    name: 'OvertimePay',
    components: {
        CarrotDatePicker, CarrotTime24Select
    },
    props: {
        modelValue: {
            type: [String, Object],
            default: "",
        },
        mode : {
            type : String,
            default: "Write"
        },
        price : {
            type : Number,
            default : 12500,
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const comp = reactive({
            showClassSelector: false,
            selectedClassSelector: 0,
            sdate:"",
            total: {
                price: computed(() => {
                    let total =0;
                    for(let i=0;i<comp.items.length;i++) {
                        if(comp.items[i].etime>="22:00") {
                            total = total + 30000;
                        } else if(comp.items[i].etime>="20:00") {
                            total = total + 20000;
                        } 
                    }
                    return Math.floor(total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }),
            },
            items: [{
                cdate:"",
                etime: "18:00",
                memo: "",
            }],

            strTimeToMin: (hm) => {
                let tm = hm.split(':');
                let min = (+tm[0]) * 60 + (+tm[1]);
                return min
            },

            removeItem: () => {
                comp.items.pop();
            },
            showClass: (idx) => {
                comp.selectedClassSelector = idx;
                comp.showClassSelector = true;
            },
            addItem: () => {
                comp.items.push({
                    cdate:"",
                    etime: "00:00",
                    memo: "",
                });
            },
            getJSON: () => {
                let data = {
                    list : comp.items,
                    total : comp.total,
                }
                return data;
            },
            update: () => {
                let data = {
                    list : comp.items,
                    total : comp.total,
                }
                emit('update:modelValue', data);
            },
            dateToString() {
                return [comp.sdate.getFullYear(),((comp.sdate.getMonth() + 1)>9 ? '' : '0') + (comp.sdate.getMonth() + 1),(comp.sdate.getDate()>9 ? '' : '0') + comp.sdate.getDate()].join('-');
            },
            intToCost(price) {
                let cost = price.toString().replace(/,/g, "");
                cost = parseFloat(cost);
                if(isNaN(cost)) {
                    cost = 0;
                }
                return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal && props.modelValue!=null && props.modelValue!="") {
                comp.sdate = nVal;
            }
        });

        onMounted(() => {
            // Mounted
            if(props.modelValue!="") {
                let info = props.modelValue;
                comp.items = info.list;
            }
        });

        return {comp, props};
    }
}
</script>
<style lang="scss" scoped>
.dept-select {
    display: inline-block;
    select {
        width: 200px;
        margin-right: 10px;
    }
}
.workinfo {
    white-space: pre-wrap;
    height: auto;
    min-height: 100px;
}
</style>