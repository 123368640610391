<template>
    <div>
        <div v-if="props.mode=='Write'">

            <ShippingAddressPopup v-if="pop.isShow" @close="pop.isShow=false" @save="comp.setAddress"></ShippingAddressPopup>

            <table class="table-row mt-10" v-for="(itm, idx) in comp.items" :key="idx">
                <tbody>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 교재 명</th>
                        <td>
                            <select class="w-300px" v-model="itm.tmp_idx_book" @change="comp.selectedBook(idx)">
                                <option value="">교재 선택</option>
                                <option v-for="(b, i) in comp.books" :key="i" :value="i">{{b.title}}</option>
                            </select>
                            <div class="mt-10 color-1">*원하는 교재가 없는 경우 경영기획실 Cina(정수민)님께 문의해 주세요.</div>
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 수량</th>
                        <td>
                            <input type="text" class="w-150px" v-model="itm.count">
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 금액</th>
                        <td>
                            {{comp.intToCost(itm.book_price * itm.count)}}원
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 출판사</th>
                        <td>
                            {{itm.publisher}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 교재 링크</th>
                        <td>
                            <input type="text" class="w-100per" v-model="itm.link">
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 용도</th>
                        <td>
                            <label><input type="radio" v-model="itm.use" value="학습자용 주교재"><span class="ml-5 mr-20"> 학습자용 주교재</span></label>
                            <label><input type="radio" v-model="itm.use" value="학습자용 부교재"><span class="ml-5 mr-20"> 학습자용 부교재</span></label>
                            <label><input type="radio" v-model="itm.use" value="강사용 교재"><span class="ml-5 mr-20"> 강사용 교재</span></label>
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 언어</th>
                        <td>
                            <label><input type="radio" v-model="itm.language" value="영어"><span class="ml-5 mr-20"> 영어</span></label>
                            <label><input type="radio" v-model="itm.language" value="일본어"><span class="ml-5 mr-20"> 일본어</span></label>
                            <label><input type="radio" v-model="itm.language" value="중국어"><span class="ml-5 mr-20"> 중국어</span></label>
                            <label><input type="radio" v-model="itm.language" value="한국어"><span class="ml-5 mr-20"> 한국어</span></label>
                            <label><input type="radio" v-model="itm.language" value="기타"><span class="ml-5 mr-20"> 기타</span></label>
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 유/무상</th>
                        <td>
                            <label><input type="radio" v-model="itm.is_free" value="N"><span class="ml-5 mr-20"> 유상</span></label>
                            <label><input type="radio" v-model="itm.is_free" value="Y"><span class="ml-5 mr-20"> 무상</span></label>
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 고객사명</th>
                        <td>
                            <!-- <input type="text" class="w-100per" v-model="itm.company" > -->
                            <CarrotCompany v-model:companyname.sync="itm.company" v-model="itm.idx_company" class="w-300px"></CarrotCompany>

                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 배송주소</th>
                        <td>
                            <button class="btn-default" @click="comp.showAddress(itm, idx)">주소입력</button>
                            {{(itm.postcode==''?'':'[' + itm.postcode + "]")}} {{itm.address}} {{itm.address_sub}} {{itm.receiver_name}} {{(itm.receiver_phone==''?'':'('+itm.receiver_phone+')')}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 출고희망일</th>
                        <td>
                            <CarrotDatePicker v-model="itm.out_date"></CarrotDatePicker>
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 구입사유</th>
                        <td>
                            <input type="text" class="w-100per" v-model="itm.reason">
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div class="dp-table w-100per mt-10">
                <button class="btn-default float-left" @click="comp.addItem">+ 교재 추가</button>
                <button class="btn-default float-right" @click="comp.removeItem">- 교재 삭제</button>
            </div>


        </div>
        <div v-if="props.mode=='View'">
            <table class="table-row mt-10" v-for="(itm, idx) in comp.items" :key="idx">
                <tbody>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 교재 명</th>
                        <td>
                            {{itm.book_title}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 수량</th>
                        <td>
                            {{itm.count}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 금액</th>
                        <td>
                            {{comp.intToCost(itm.book_price * itm.count)}}원
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 출판사</th>
                        <td>
                            {{itm.publisher}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 교재 링크</th>
                        <td>
                            {{itm.link}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 용도</th>
                        <td>
                            {{itm.use}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 언어</th>
                        <td>
                            {{itm.language}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 유/무상</th>
                        <td>
                            {{itm.is_free=='Y'?'무상':'유상'}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 고객사명</th>
                        <td>
                            {{itm.company}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 배송주소</th>
                        <td>
                            {{(itm.postcode==''?'':'[' + itm.postcode + "]")}} {{itm.address}} {{itm.address_sub}} {{itm.receiver_name}} {{(itm.receiver_phone==''?'':'('+itm.receiver_phone+')')}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 출고희망일</th>
                        <td>
                            {{itm.out_date}}
                        </td>
                    </tr>
                    <tr>
                        <th width="150"><span class="txt-red">*</span> 구입사유</th>
                        <td class="workinfo">
                            {{itm.reason}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';
import ShippingAddressPopup from '@/components/popup/payment/ShippingAddressPopup.vue'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification';
import CarrotCompany from '@/components/common/CarrotCompany.vue'

export default {
    name: 'ExternalBook',
    components: {
        CarrotDatePicker, ShippingAddressPopup, 
        CarrotCompany,
    },
    props: {
        modelValue: {
            type: [String, Object],
            default: "",
        },
        mode : {
            type : String,
            default: "Write"
        },
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const toast = useToast()

        const pop = reactive({
            idxSelected : 0,
            isShow : false
        });
        const comp = reactive({
            items: [{
                tmp_idx_book : "",
                idx_book :"",
                book_title : "",
                book_price : "",
                count : "",
                price : 0,
                publisher : "",
                link : "",
                use : "",
                language : "",
                is_free : "",
                idx_company : 0,
                company : "",
                postcode : "",
                address : "",
                addresssub : "",
                receiver_name : "",
                receiver_phone : "",
                out_date : "",
                reason : ""
            }],
            books: [],

            getBookList : () => {
                let params = {
                    mode: "view"
                };
                axios.get('/rest/documents/getBookList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.books = res.data.list;

                        //기존 값 있는 경우 idx_book 기반으로 tmp_idx_book 갱신.
                        for(let j=0; j<comp.items.length; j++){
                          for(let i=0; i<comp.books.length; i++){
                            if(comp.books[i].idx === comp.items[j].idx_book){
                              comp.items[j].tmp_idx_book=i;
                            }
                          }
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            selectedBook : (idx) => {
                if(idx==="") {
                    comp.items[idx].idx_book = 0;
                    comp.items[idx].book_title = "";
                    comp.items[idx].book_price = 0;
                    comp.items[idx].publisher = "";
                    return;
                }
                comp.items[idx].idx_book = comp.books[comp.items[idx].tmp_idx_book].idx;
                comp.items[idx].book_title = comp.books[comp.items[idx].tmp_idx_book].title;
                comp.items[idx].book_price = comp.books[comp.items[idx].tmp_idx_book].price;
                comp.items[idx].publisher = comp.books[comp.items[idx].tmp_idx_book].publisher;
            },

            setAddress : (addr) => {
                comp.items[pop.idxSelected].postcode = addr.zipcode;
                comp.items[pop.idxSelected].address = addr.address;
                comp.items[pop.idxSelected].address_sub = addr.addresssub;
                comp.items[pop.idxSelected].receiver_name = addr.kname;
                comp.items[pop.idxSelected].receiver_phone = addr.phone;
            },

            showAddress : (dat, idx) => {
                console.info(dat);
                pop.idxSelected = idx;
                pop.isShow = true;
            },

            strTimeToMin: (hm) => {
                let tm = hm.split(':');
                let min = (+tm[0]) * 60 + (+tm[1]);
                return min
            },

            removeItem: () => {
                comp.items.pop();
            },
            showClass: (idx) => {
                comp.selectedClassSelector = idx;
                comp.showClassSelector = true;
            },
            addItem: () => {
                comp.items.push({
                    tmp_idx_book : "",
                    idx_book :"",
                    book_title : "",
                    count : "",
                    price : 0,
                    publisher : "",
                    link : "",
                    use : "",
                    language : "",
                    is_free : "",
                    company : "",
                    idx_company : 0,
                    postcode : "",
                    address : "",
                    address_sub : "",
                    receiver_name : "",
                    receiver_phone : "",
                    out_date : "",
                    reason : ""
                });
            },
            getJSON: () => {
                let data = {
                    list : comp.items,
                }
                return data;
            },
            update: () => {
                let data = {
                    list : comp.items,
                }
                emit('update:modelValue', data);
            },
            dateToString() {
                return [comp.sdate.getFullYear(),((comp.sdate.getMonth() + 1)>9 ? '' : '0') + (comp.sdate.getMonth() + 1),(comp.sdate.getDate()>9 ? '' : '0') + comp.sdate.getDate()].join('-');
            },
            intToCost(price) {
                let cost = price.toString().replace(/,/g, "");
                cost = parseFloat(cost);
                if(isNaN(cost)) {
                    cost = 0;
                }
                return  cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal && props.modelValue!=null && props.modelValue!="") {
                comp.sdate = nVal;
            }
        });

        onMounted(() => {
            // Mounted
          if(props.modelValue!="") {
            let info = props.modelValue;
            comp.items = info.list;
          }
          comp.getBookList();


        });

        return {comp, pop, props};
    }
}
</script>
<style lang="scss" scoped>
.dept-select {
    display: inline-block;
    select {
        width: 200px;
        margin-right: 10px;
    }
}
.workinfo {
    white-space: pre-wrap;
    height: auto;
    min-height: 100px;
}
</style>