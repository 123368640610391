<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-500px">
            <div>
                <p class="title">배송주소</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light overhidden overscroll maxh-600px">

                <table class="tbl-light-view">
                    <tbody>
                        <tr>
                            <th width="120"><span class="txt-red">*</span> 받는 분 성함</th>
                            <td>
                                <input type="text" class="w-70per h-32px" v-model="pop.info.kname">
                            </td>
                        </tr>
                        <tr>
                            <th width="120"><span class="txt-red">*</span> 받는 분 연락처</th>
                            <td>
                                <input type="text" class="w-70per h-32px" v-model="pop.info.phone">
                            </td>
                        </tr>
                        <tr>
                            <th width="120"><span class="txt-red">*</span> 배송 주소</th>
                            <td class="text-input">
                                <CarrotAddress v-model:zipcode.sync="pop.info.zipcode" v-model:address.sync="pop.info.address"></CarrotAddress>
                                <input type="text" v-model.trim="pop.info.addresssub" class="w-100per mt-5" placeholder="상세주소를 입력하세요.">

                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="layer-btn mt-10 txt-center">
                <button class="btn-default dp-inblock" @click="pop.onSave">등록</button>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import CarrotAddress from '@/components/common/CarrotAddress.vue';

export default {
    name: 'ShippingAddressPopup',
    components: {
        CarrotAddress
    },
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    emits: [ 'close', 'save' ],
    setup(props, {emit}) {
        const pop = reactive({
            info : {
                kname : "",
                phone : "",
                address : "",
                addresssub : "",
                zipcode : ""
            },
            onSave : () => {
                emit('save', pop.info);
                emit('close');
            },
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" >
    .text-input {
        line-height: 300%;
        input[type=text] {
            height: 32px;
        }
    }
</style>